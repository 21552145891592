import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { Demo, DemoImage } from "../components/Demo";
import { Details } from "../components/Details";
import { GradientText } from "../components/GradientText";
import { Section } from "../components/Section";
import { Title } from "../components/Title";

const FeatureSection = ({
  children,
  grayer,
  right,
  center,
}: {
  children: ReactNode;
  grayer?: boolean;
  right?: boolean;
  center?: boolean;
}) => (
  <Section
    grayer={grayer}
    fullWidth
    className={`col items-center ${
      center ? "" : right ? "md:flex-row-reverse" : "md:flex-row"
    }`}
  >
    {children}
  </Section>
);


const ImageDemo = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    imgSrc: string;
    bumpLeft?: boolean;
    center?: boolean;
    className: string;
    alt: string;
    height: number;
    width: number;
  }
) => {
  const {
    imgSrc,
    bumpLeft,
    center,
    alt,
    className,
    height,
    width,
    ...divProps
  } = props;
  return (
    <div
      {...divProps}
      className={twMerge(
        `w-5/6 md:w-1/2 p-4 md:p-12 bg-gradient-to-br rounded-xl ${
          center ? "" : bumpLeft ? "md:-translate-x-14" : "md:translate-x-14"
        }`,
        className
      )}
    >
      <DemoImage imgSrc={imgSrc} alt={alt} height={height} width={width} />
    </div>
  );
};

const Text = ({
  children,
  center,
}: {
  children: ReactNode;
  center?: boolean;
}) => (
  <div
    className={`w-5/6 col gap-4 text-center mb-8 mx-auto ${
      center ? "md:w-2/3" : "md:w-2/5 md:text-left md:my-auto"
    }`}
  >
    {children}
  </div>
);

export const MissionFeatures = () => {
  return (
    <>
      <FeatureSection grayer right>
        <Text>
          <Title size="md">The Problem</Title>
          <Details>
            The healthcare industry in the U.S. is broken. Insurers routinely
            deny coverage in ways inconsistent with law, their own contracts,
            and medical literature. Denials cause delays, forgone care, and physical and financial harm,
            with calamitous effects for the most vulnerable.
          </Details>
          <Details>
            People deserve access to the services afforded to them by their
            contracts and law. They should be able to focus on their wellbeing,
            rather than holding billion dollar corporations accountable for
            inappropriate denials.
          </Details>
        </Text>
        <ImageDemo
          bumpLeft
          imgSrc="/images/exec.webp"
          className="from-persius_green-400 to-persius_green-700"
          alt="An executive signing a paper."
          height={100}
          width={550}
        />
      </FeatureSection>
      <FeatureSection grayer center>
        <Text center>
          <Title size="md">Our Approach</Title>
          <Details className="w-75">
            While many facets of our healthcare system cause issues for patients,
            we focus on driving change in the practices of insurers. The
            most efficient way to do so is to financially disincentivize harmful,
            illegal, and bad faith behaviors.{" "}
          </Details>

          <Details>
            We develop AI and work with patients to scale appeals of
            inappropriate denials, and thereby drive systemic change via
            collective action. The data makes clear that the pervasive
            inappropriate denials we see today would simply not be financially
            viable were appeal utilization sufficiently high.
          </Details>
          <Details>
            As a society we recognize that individuals should be held
            responsible when they break contractual obligations or law, but due
            to their influence, power, and ability to incur hefty legal fees, as
            well as minimal enforcement, we continue to see insurers get away
            with illegal practices with palatable consequences. This is
            unacceptable. In collaboration with the patients we serve we are
            building an evidence base for bad faith behaviors that will lay the
            groundwork for true accountability.
          </Details>
        </Text>
      </FeatureSection>
      {/* <FeatureSection grayer>
        <Text>
          <Title size="md">
            People Are{" "}
            <GradientText className="from-primary-600 to to-neon-amber">
              Sick
            </GradientText>
          </Title>
          <Details>
            Prior authorization and claims denials in the U.S. pose serious
            problems for patients. They cause delays, forgone care, and
            detrimental health outcomes. Sometimes patients receive services
            despite denials, incurring bills, debt, and collections lawsuits. In
            both cases, there are calamitous effects for the most vulnerable.
            People deserve access to the services afforded to them by their
            health insurance polciies. They should be able to focus on their
            wellbeing, rather than holding billion dollar corporations
            accountable for inappropriate delays and denials.
          </Details>
        </Text>
        <ImageDemo
          imgSrc="/images/iv.webp"
          className="from-primary-600 to to-neon-amber"
          alt="An image showing a person receiving an injection."
          height={100}
          width={550}
        />
      </FeatureSection> */}
      {/* <FeatureSection grayer>
        <Text>
          <Title size="md">
            Health Insurance Is{" "}
            <GradientText className="from-primary-600 to-persius_green-500">
              Broken
            </GradientText>
          </Title>
          <Details></Details>
        </Text>
        <ImageDemo
          imgSrc="/images/exec.webp"
          className="from-primary-600 to-persius_green-500"
          alt="An executive signing a paper."
          height={100}
          width={550}
        />
      </FeatureSection> */}
      {/* Feature 2 */}
      <Section className={`w-full md:w-auto md:flex-row`}>
        <Title size="lg">
          We've resolved{" "}
          <GradientText className="from-persius_green-600 to-persius_blue-500">
            $547,709
          </GradientText>{" "}
          in denials, securing access to critical coverage in{" "}
          <GradientText className="from-persius_green-600 to-persius_blue-500">
            92%
          </GradientText>{" "}
          of cases.
        </Title>
      </Section>
      <FeatureSection right>
        <Text>
          <Title size="md">We Are Determined</Title>
          <Details>
            We are unwavering in our commitment to supporting patients, seeking
            justice and systemic change, and deploying AI responsibly.
            We&apos;ve been doing this work for years, and have been around long
            enough to see opportunistic efforts looking to
            &quot;support the cause&quot; come and go. We&apos;ll be here until
            the system changes, whether or not it&apos;s lucrative.
          </Details>
          <Details>
            We support vulnerable patients facing complicated denial situations,
            even when the battles are uphill. We do not restrict focus to the easiest or most monetarily "valuable"
            denials, and are committed to supporting those in greatest need.
          </Details>
        </Text>
        <ImageDemo
          imgSrc="/images/volunteers.webp"
          bumpLeft
          className="from-persius_blue-400 to-persius_blue-700"
          alt="An image showing a group of resolute volunteers."
          height={400}
          width={600}
        />
      </FeatureSection>
      {/* Feature 3 */}
      <FeatureSection grayer>
        <Text>
          <Title size="md">
            We Are{" "}
            {/* <GradientText className="from-primary-600 to-persius_blue-500"> */}
            Diligent
            {/* </GradientText> */}
          </Title>
          <Details>
            While many are focused on generating appeals as fast as possible,
            without regard for risks disproportionately shouldered by the most
            vulnerable, we prioritize quality and responsible deployment. Our
            priorities have clear results for those we work with.
          </Details>
          <Details>
            {" "}
            Our appeals are comprehensive, detailed, and comprised of carefully
            vetted evidence. On average they are longer than ten pages, and
            contain a complex web of references to contractual and medical
            literature. We don&apos;t submit hallucinated content on behalf of
            patients facing dire physical consequences, and hope for the best.
          </Details>
        </Text>
        <ImageDemo
          imgSrc="/images/letter.webp"
          className="from-primary-600 to-primary-400"
          alt="An image showing a detailed insurance appeal letter."
          height={400}
          width={600}
        />
      </FeatureSection>
      <FeatureSection grayer center>
        <Text center>
          <Title size="md">
            We Are{" "}
            <GradientText className="from-persius_blue-600 to-persius_green-600">
              Seeking Funding
            </GradientText>
          </Title>
          <Details>
            Our vision is ambitious, and our goal to realize systemic change
            will require large scale collective action fueled by sophisticated,
            emerging technology. We believe we are the right people to build it.
          </Details>
          <Details>
          We are pursuing a path free from shareholders. We are actively
            seeking grants, and intending to ultimately sustain ourselves through earned revenue
            selling our technology and services to providers and hospitals that spend
            enormous resources supporting patients through these processes. All
            of the services we provide directly to patients are free.
            </Details>
          <Details>
            If you are interested in learning more about our work, and
            potentially funding our zero cost work in support of vulnerable community members, or supporting us in other ways, please reach
            out to{" "}
            <a
              href="mailto:info@persius.org"
              className="underline text-primary-400"
            >
              info@persius.org
            </a>
            .
          </Details>
        </Text>
      </FeatureSection>
    </>
  );
};

export const HomeFeatures = () => {
  return (
    <>
      <FeatureSection right>
        <Text>
          <Title size="md">
            We Put{" "}
            <GradientText className="from-persius_blue-500 to-persius_blue-600">
              People
            </GradientText>{" "}
            Over Profit
          </Title>
          <Details>
            People are our priority, and we take our responsibility to do no
            harm seriously.
          </Details>
          <Details>
            We are an AI company building tools to support patients, and believe
            deeply in the role tech can play in driving systemic change. It's
            why we've been doing this work for years.
          </Details>
          <Details>
            We use AI to responsibly provide support, rather than recklessly
            deploying models prematurely to generate hype and funding.
          </Details>
        </Text>
        <ImageDemo
          imgSrc="/images/family.webp"
          className="from-persius_blue-400 to-persius_blue-600"
          bumpLeft
          alt="An image showing a happy family."
          height={400}
          width={600}
        />
      </FeatureSection>
    </>
  );
};
