// External imports
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import { Navigate, createSearchParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

// Internal Imports
import { login } from "../utils/auth.ts";

import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiInputLabel-root": {
    color: "#E0E3E7", // Correct property for placeholder color
    opacity: 0.7, // Ensure full opacity
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
  },
});

export default function Login(props) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [needsEmailVerification, setNeedsEmailVerification] = useState(false);
  const loc_state = useLocation();
  let isLoggedIn = props.isLoggedIn;
  let setIsLoggedIn = props.setIsLoggedIn;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  useEffect(() => {
    if (isLoggedIn && loc_state?.state?.path) {
      navigate(loc_state?.state?.path, { replace: true });
    } else if (isLoggedIn) {
      navigate("/");
    } else if (needsEmailVerification) {
      navigate({
        pathname: "/verify-email",
        search: createSearchParams({
          username: username,
        }).toString(),
      });
    }
  }, [isLoggedIn, needsEmailVerification, loc_state, navigate, username]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    try {
      const data = await login(username, password);

      if ("access_token" in data) {
        setIsLoggedIn(true);
      } else if (
        data.detail === "User exists. Login requires email verification."
      ) {
        setNeedsEmailVerification(true);
      } else {
        setError("Network error.");
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(String(err));
      }
    }
  };

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="100%"
        className="bg-extra-strong"
        sx={{
          flex: 1, // Allows Container to grow within the Box
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Center content vertically
          alignItems: "center",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className="text-light">
              Sign in
            </Typography>
            <Box
              noValidate
              sx={{ mt: 1 }}
              component="form"
              onSubmit={handleSubmit}
            >
              <CssTextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                onChange={(e) => setUsername(e.currentTarget.value)}
                autoComplete="username"
                autoFocus
                sx={{
                  input: { color: "#E0E3E7" },
                }}
              />
              <CssTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.currentTarget.value)}
                id="password"
                autoComplete="current-password"
                sx={{ input: { color: "#E0E3E7" } }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ color: "#E0E3E7" }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                // onClick={handleSubmit}
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid2 container>
                {/* <Grid2 item xs>
                <Link component={RouterLink} to={"/contact"} variant="body2">
                  Forgot password?
                </Link>
              </Grid2> */}
                <Grid2>
                  <Link component={RouterLink} to={"/signup"} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid2>
              </Grid2>
            </Box>
            <Grid2 container mt={3} justifyContent="center">
              {error && (
                <Grid2 justifyContent="center">
                  <Alert severity="error">{error}</Alert>
                </Grid2>
              )}
            </Grid2>
          </Box>
        </Container>
      </Container>
    </React.Fragment>
  );
}
