import { ReactNode } from "react";

import { Card } from "../components/Card";
import { Section } from "../components/Section";
import { Title } from "../components/Title";
import { GradientText } from "../components/GradientText";

import { ScatteredSpheres2 } from "../svg/ScatteredSpheres";

const Background = () => (
  <div
    className="absolute inset-0 translate-y-32 pointer-events-none dark:invert dark:brightness-90"
    aria-hidden="true"
  >
    <ScatteredSpheres2 />
  </div>
);

const TestimonialImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <div className="absolute mx-auto -top-5">
      {/* <Quote /> */}
      <img
        // className="rounded-full"
        src={src}
        width="48"
        height="48"
        alt={alt}
      />
    </div>
  );
};

const TestimonialText = ({
  quote,
  name,
}: // title,
{
  quote: string;
  name: string;
  // title: string;
}) => {
  return (
    <>
      <blockquote className="font-medium">&quot;{quote}&quot;</blockquote>
      <div className="">
        <cite className="not-italic font-bold">— {name}</cite>
        {/* <div className="text-base text-light">
          <span>{title}</span>{" "}
        </div> */}
      </div>
    </>
  );
};

const Testimonial = ({ children }: { children: ReactNode }) => {
  return (
    <Card
      className="items-center gap-6 p-12 pt-20 body-lg col text-medium"
      colorBorder
    >
      {children}
    </Card>
  );
};

export const Testimonials = () => {
  return (
    <Section grayer className="gap-24 text-center">
      <Background />
      {/* Header */}
      <div className="gap-4 col">
        <Title size="md">What People Are Saying</Title>
      </div>
      {/* Testimonials */}
      <div className="gap-20 md:gap-6 col md:row">
        {/* Testimonial 1 */}
        <Testimonial>
          <TestimonialImage src="/images/quote.webp" alt="Sara Mari Flores" />
          <TestimonialText
            quote="Persius was instrumental in the success of my appeal for an emergency out of network bill that my health insurance initially denied. Patient and thorough. Working with Persius through the appeals process was wonderful."
            name="Sara Mari Flores"
            // title="Mother of Patient"
          />
        </Testimonial>
        <Testimonial>
          <TestimonialImage src="/images/quote.webp" alt="Carson" />
          <TestimonialText
            quote="You do amazing work! Without you we would be in debt over our heads. I couldn’t ask for anyone better to help with appeals for insurance denials."
            name="Carson"
            // title="Patient"
          />
        </Testimonial>
        {/* Testimonial 2 */}
        <Testimonial>
          <TestimonialImage src="/images/quote.webp" alt="Anonymous" />
          <TestimonialText
            quote="Persius' patience, expertise, and willingness to jump in and guide us through such a stressful and time-sensitive external appeal helped me receive life sparing treatment in a timely manner.  It’s hard to express how much easier they made this process for me and my family. Invaluable support.  Highly recommend."
            name="Anonymous"
            // title="Patient"
          />
        </Testimonial>
        {/* Testimonial 3 */}
      </div>
    </Section>
  );
};
